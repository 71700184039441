import React from 'react';
import { Box } from '../Box';
import { VisuallyHidden } from '../VisuallyHidden';

type PreHideComponentProps = {
  children?: React.ReactNode;
  isVisible?: boolean;
};

export const PreHide = React.forwardRef<HTMLDivElement, PreHideComponentProps>(
  ({ children, isVisible }, ref) => {
    const component = <Box ref={ref}>{children}</Box>;

    if (!isVisible) {
      return <VisuallyHidden>{component}</VisuallyHidden>;
    }

    return component;
  }
);

