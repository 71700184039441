import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fglobal%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VW227jNhB971cMYCzQBRyBHN4k7lPWl8eif1BIouQ4cWxHcrJOiv57QW3cQkNOgcaIYJ%2B5HY7mkCz%2BkONeHaUS8OcvAHd3t9%2FSwwK%2FY4nu2wxHD4vt9DfHlYdFiXLj1nNce1gI4ap7NceNh4XeStSrOW6ZPI6pWzJ4xeA1k7%2BZeBrj7BxvGf6Byd8xeM%2FgO6bPDwyfPcP%2FkcnzxOQ5MOt6ZngeGfzE4GcGf2HwgeE%2FMv4Xxv%2BV8X9j8B9MnquHRW%2FiZ46%2FR1y2WvRz%2FMPDQtZNW7VzXAqmsIwK295v9VYQAzKvTKp%2FuCoao3mT4QhYjoBjeiJLZvhkVNtmtV5viJpllJtdycqWxBD1ZlbSmIoYWmZjkIGZWBklt642qy019FOELcsNMex%2BdnjlaEs41ckoO7f%2BrhVlFXW3MVuxpguMwutC77pADFF5neltTw1Ren3f646MnIza60MXklQnriVnbh0vXMQwDUPlJO1VFOBauErQXl08XIb6OJ7roTteiPGVG6G3%2F4r6wY3qlRu8d25aPpiFomBag5weEZniqBi6qLnD0zBdRssZOC0id%2FBhxdGNWsTOBYXEMJ19WJZBEkM7zbBtBY2IWgwKeyS7IHaMTjBq8V4JIWh3dx7E%2BQpSxGcZH8OuqX9Vbgkol6CWIAqBX5eTl5aFc86er6BUoRFl3l1q5Zz8jEEslLIq1nBFaSsmRFaItxCJhUFpJ1qFkA6ZmE93W1gjIhVTKBSizHuLUjhX2hurwlmHdvqGUpXMSoSxiJX8Spr24GF%2FHLsLiOkTg%2FNTss84Zqfm0U%2FG8XTYBybX08wlm%2BXgIfYq1ONDFxg5Pc99siI9etDnKwFP7BmH%2F942EtMLJ4eB2yFGTnIXZrPBV64Gd%2BtA7tqBV2a7xXdOWR%2BMslTc6ZoQqqYjhrjT1W1oeiLr6XLfNwFrcomZbve9aroklZ7US0AzvWEC2swrjbf6MgFLDyLCt39irqL5pzhvD%2BJRezDiCwGbHNM2RyrkSHUeZLqo3ufq7zxgWuvBA6bF9h4wrfboQaXVnjyotNrBg06rPXvQad6jB5NmOHmwKbOzB5dyePHTJk3QwUOV5h1jz1LnS2xaCr%2FGrqXwm4ffh9N1%2F1zDb6e3egn3w74%2BLGGsj%2BPd2A17cgTFG%2F3%2FC7h6sIl43mNXKfiRa6oWHmRBjk4tc29FY85V5V621tGVbE3a5KZF25yry42bLnOulYd4khK0zrk2HmRKoI2u5NaqQ04busu5RhmlXHefrn%2F9DXoeySOlEAAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fcomponents%2FFollowUpSteps%2FFollowUpSteps.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA7VXTW%2FiMBC98yusSkhEqlESCLTmsoc97nEPe1uZ2AG3wY4c00JX%2FPdVnC87cSAg9UbIfLw382bszL%2F25wWlPvg3AYCwPEvxGQEuON1MAPhkRO0RCKNp8bSnbLdXCAR%2Bdiqet0ISKqESGQJhdgIE53tKgJKY5xmWlKvCKhM5U0xwBPA2F%2BlR6cjaaeVPN5PLZF5hmNMPyjUQWSfqGAhC9PuUJq7X8TFX4kDlTw3kF%2BNUW1c4Y5EKicAHljMI%2FwY5W%2FBgERDvRgi0pYmQ9Blcs8GJorJMhuP3nRRHTkYmFIcM8%2FM9kF9uRHAg7pncAbiTrmhSlUH7x4IryhUCT0%2BGZGqNDGhGYsKOOQKRPx3WiKWCSjK%2B%2FlUoLBHygMqfKVZ0FvnTZwAjf%2BoVJl%2BQcUJPCERdARnUvw%2B6KdCbyOEI6IEG3CaTNMWKfehkByx3jMNtKuJ3mCssVYGuAN26X%2BnY0NTfV4eScYzTeFYTcZH9M2t4uivXsA%2BaysHQ5tIMoEnqvrkL6pG4K4Q1CWGnIw7l2u4L4jWUHG8uk4bXb7wbB4iagBaPc1k8vA%2BWDwCNHvBZXdF%2Fo1frOFgND3qr6GUp6fqcswReKto42Bx1uD7%2Ffivhha3h9Vg6nemmnPTE82bWaT2GdRn420kH9g5aI672MN6zlMwEIV5zPpkvitPF0%2BhdW803wr2gFOeqdDNId7ZZY%2F6q3yaCK5izL4pAsCqJ2wvHvdyqUkWhvRHrZ3c5ylBlGUAuUtYrZqCL%2BXbMFUvOsGlXTLmi0lrNSUp1JpyyHYdM0UNu2jkHKNTRNePPCvDK912NGnk0NbXEDyyK7QNbOh7nw7TPjwMlDIM8lpRygDkBswPjsJmmcJmdSmXNzTuvUWQttaIUl9bIOjhv2K4qowwTwvgOujZ95nWdDO12T1EAQZCd%2Bh4WpM6Uj%2ByLNnRqvX%2FgO2BFFawh7dcX%2FHUdoXOnsG85Fr11xavyWJaoTIPOHkFcqBlKmKy3gVdFcG0QGPgVhIGI7QJqAjCeMk7rCPioxObe%2BGaPb%2FYn8Lp9uF2DvohanNVtRLcPhpEpK3MBXCvIkEjHhRtY1a5xMpZrj%2FSrrYzVS62l2qX%2Bx%2FAhlU%2F7vWlUxN846zXi88LIQJ0Zxlz0L5PLf3YUmSJ%2FDwAA%22%7D"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var cardRecipe = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{hasConclusion:{false:{initial:'zhy3ee8'}}},compoundVariants:[],defaultVariants:{hasConclusion:false},baseClassName:'zhy3ee7'});
export var cardsWrapper = 'zhy3ee1';
export var companyTag = 'zhy3ee3';
export var conclusionBlock = 'zhy3ee6';
export var counter = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{variant:{company:{initial:'zhy3eea'},customer:{initial:'zhy3eeb'},checked:{initial:'zhy3eec'}},index:{odd:{initial:'zhy3eed'},even:{initial:'zhy3eee'}}},compoundVariants:[],defaultVariants:{variant:'company',index:'even'},baseClassName:'zhy3ee9'});
export var customerTag = 'zhy3ee4';
export var dashedLine = 'zhy3ee0';
export var solvedTag = 'zhy3ee5';
export var tag = 'zhy3ee2';