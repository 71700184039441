import React from 'react';
import NextLink from 'next/link';
import { GetStaticPaths, GetStaticProps } from 'next';
import {
  SitecoreContext,
  ComponentPropsContext,
  PlaceholdersData,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs/utils';

import { DictionaryItem, useAdsContext } from '@ads-core/providers';

import sitecoreRedirect from 'lib/sc-redirect';
import fileBasedRedirect from 'lib/filebased-redirect';

import Layout from 'src/components/Layout';
import { sitecorePagePropsFactory } from 'src/jss/lib/page-props-factory';
import { CACHE_CONTROL_AGES } from 'src/data/constants';
import { componentBuilder } from 'temp/componentBuilder';

import { NewsArticleDetailJssProps } from 'components/NewsArticleDetail';
import { isComponentRendering } from 'src/utils/isComponentRendering';
import { useAdobeTargetView } from 'src/hooks/useAdobeTargetView';
import { SitecorePagePropsEnriched } from './_app';

const SitecorePage = ({ componentProps, layoutData }: SitecorePagePropsEnriched): JSX.Element => {
  const { setIds, setDictionary } = useAdsContext();
  const tooltips = layoutData.sitecore.context.tooltips as (DictionaryItem & {
    tooltip: { button: { href: string; text: string; isExternal: boolean; target: string } };
  })[];

  const mappedTooltips = React.useMemo(
    () =>
      tooltips.map(({ tooltip: { button, ...tooltipRest }, ...rest }) => {
        const Component = button?.isExternal ? 'a' : NextLink;
        return {
          ...rest,
          tooltip: {
            ...tooltipRest,
            button:
              button && button.href && button.text ? (
                <Component href={button?.href} target={button.target}>
                  {button.text}
                </Component>
              ) : undefined,
          },
        };
      }),
    [tooltips]
  );

  React.useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  useAdobeTargetView();

  React.useEffect(() => {
    // Add tooltip dictionary to context
    if (mappedTooltips.length > 0) {
      setDictionary(mappedTooltips);
    }

    // Add separation ids to context
    const placeholders = layoutData.sitecore.route?.placeholders;

    if (placeholders) {
      const idsTest = placeholders['jss-separation']
        // Experience editor adds <code> elements to the placeholder, so the first element in the normal mode isnt always the firrst element in the editor mode
        .filter((item: any) => item.uid !== undefined)
        .slice(1)
        .map((item) => (item as { uid?: string }).uid)
        .filter((uid) => uid !== undefined) as string[];

      setIds(idsTest);
    }
  }, [tooltips, layoutData.sitecore.route?.placeholders, mappedTooltips, setDictionary, setIds]);

  //  if (notFound || !layoutData.sitecore.route) {
  //    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
  //    return <NotFound />;
  //  }

  const isEditing = layoutData.sitecore.context.pageEditing;

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext
        componentFactory={componentBuilder.getComponentFactory({ isEditing })}
        layoutData={layoutData}
      >
        <Layout layoutData={layoutData} />
      </SitecoreContext>
    </ComponentPropsContext>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const props = await sitecorePagePropsFactory.create(context);

  // If a page does NOT exist, then look for a redirect
  const path = context.params?.path;

  if (props.notFound && path) {
    // Path elements can come in as an array of each path element
    const sourceUrl = '/' + (Array.isArray(path) ? path.join('/') : path);

    // Check hardcoded redirect list
    let redirect = fileBasedRedirect(sourceUrl);

    if (!redirect) {
      // Our sitecore managed redirect function is called here
      redirect = await sitecoreRedirect(sourceUrl);
    }

    if (redirect) {
      return {
        redirect,
      };
    }
  }

  // Because backend restrictions, we need to manually redirect in the frontend
  // We check if the newsArticleDetail has any data
  // When this component don't has any data we return a 404 page.
  const foundArticle = findArticleAndRedirect(props.layoutData.sitecore.route?.placeholders);

  return {
    props,
    revalidate: CACHE_CONTROL_AGES.MINUTE,
    notFound: props.notFound ? props.notFound : foundArticle,
  };
};

export default SitecorePage;

/**
 * Check if there's a NewsArticleDetail component and if it has any results.
 *
 * @param placeholders
 * @returns Boolean
 */
const findArticleAndRedirect = (
  placeholders: PlaceholdersData | undefined
): boolean | undefined => {
  const components = placeholders?.['jss-main'];

  if (!components) return;

  const newsArticleDetail = components.find((component) => {
    if (isComponentRendering(component)) {
      return component.componentName === 'NewsArticleDetail';
    }
  });

  if (
    newsArticleDetail &&
    isNewsArticle(newsArticleDetail) &&
    !newsArticleDetail.fields.title &&
    !newsArticleDetail.fields.body &&
    !newsArticleDetail.fields.introductionText
  ) {
    return true;
  }
};

// TypeGuard for News Article Component
export function isNewsArticle<P extends object>(
  component: NewsArticleDetailJssProps | P
): component is NewsArticleDetailJssProps {
  return (
    component && 'componentName' in component && component.componentName === 'NewsArticleDetail'
  );
}
