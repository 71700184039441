import { FilteredOutagesSMSServiceProps } from 'components/OutagesSMSService';

type NotificationProps = {
  error?: boolean;
  nrAlreadyExists?: boolean;
  outageNoLongerExists?: boolean;
  props: FilteredOutagesSMSServiceProps;
  serviceNotFound?: boolean;
  tooManyAddresses?: boolean;
  futureOutageServiceSubscription?: boolean;
  currentOutageServiceSubscription?: boolean;
};

export const notificationMessage = ({
  error,
  currentOutageServiceSubscription,
  futureOutageServiceSubscription,
  nrAlreadyExists,
  outageNoLongerExists,
  serviceNotFound,
  tooManyAddresses,
  props,
}: NotificationProps) => {
  switch (true) {
    case serviceNotFound:
      return [
        {
          text: 'We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of zoek uw netbeheerder op via <a href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">mijnaansluiting.nl</a>.',
        },
      ];
    case tooManyAddresses:
      return [
        {
          title: props.nrUsedOnTooManyAddressesTitle?.toString() || 'Te veel inschrijvingen',
          text:
            props.nrUsedOnTooManyAddressesText?.toString() ||
            'Uw telefoonnummer staat al bij drie adressen geregistreerd. Stuur een SMS met UIT naar 4004 om u af te melden. Daarna kunt u zich aanmelden voor een nieuw adres.',
        },
      ];
    case nrAlreadyExists:
      return [
        {
          title: props.nrExistsOnAddressTitle?.toString() || 'Telefoonnummer al geregistreerd',
          text:
            props.nrExistsOnAddressText?.toString() ||
            'Uw telefoonnummer is al geregistreerd op dit adres.',
        },
      ];
    case outageNoLongerExists &&
      futureOutageServiceSubscription &&
      currentOutageServiceSubscription:
      return [
        {
          title: props.outageNoLongerExistsTitle?.toString(),
          text: props.outageNoLongerExistsText?.toString(),
        },
        {
          title: props.successMessageTitle?.toString(),
          text: props.successMessageText?.toString(),
        },
      ];
    case outageNoLongerExists && !futureOutageServiceSubscription:
      return [
        {
          title: props.outageNoLongerExistsTitle?.toString(),
          text: props.outageNoLongerExistsText?.toString(),
        },
      ];
    case error:
      return [
        {
          title: props.errorMessageTitle?.toString(),
          text: props.errorMessageText?.toString(),
        },
      ];
    case futureOutageServiceSubscription || currentOutageServiceSubscription:
      return [
        {
          title: props.successMessageTitle?.toString(),
          text: props.successMessageText?.toString(),
        },
      ];
    default:
      return [
        {
          title: props.errorMessageTitle?.toString(),
          text: props.errorMessageText?.toString(),
        },
      ];
  }
};
