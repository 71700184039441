import type { ImageFieldValue, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';
import {
  ButtonLink,
  GridItem,
  Heading,
  Highlight,
  ImageWithTextComponent,
  PageGrid,
  RichText,
  Stack,
  Tag,
  Text,
  ColoredSection,
  Box,
} from '@ads-core/components';
import NextLink from 'next/link';

import React from 'react';
import { CalendarIcon, PinDropIcon } from '@ads-core/icons';
import { SitecoreImage } from '@alliander-fe/jss-utils';

import { formatDate } from 'src/utils';

export type NewsArticleDetailJssProps = ComponentProps & {
  fields: {
    body: string;
    button: LinkFieldValue;
    highlight: {
      button: LinkFieldValue;
      cardButton: LinkFieldValue;
      cardImage: ImageFieldValue;
      cardText: string;
      cardTitle: string;
      title: string;
    };
    image: ImageFieldValue;
    imageText?: string;
    publicationDate: string;
    regions: string[];
    tag: string;
    title: string;
    introductionText: string;
  };
};

const NewsArticleDetail = (props: NewsArticleDetailJssProps) => {
  const {
    body,
    button,
    highlight,
    image,
    publicationDate,
    regions,
    tag,
    title,
    imageText,
    introductionText,
  } = props.fields;
  const highlightButton = highlight.button;
  return (
    <Stack gap={{ initial: 8, md: 16 }}>
      <PageGrid>
        <GridItem columnStart={{ initial: '1', md: '3' }} columnEnd={{ initial: '-1', md: '-3' }}>
          <Stack gap={6} alignX="start">
            {tag ? (
              <Box paddingBottom={4}>
                <Tag>{tag}</Tag>
              </Box>
            ) : null}
            {title ? <Heading size="h1">{title}</Heading> : null}
            {introductionText ? <Text size="paragraph">{introductionText}</Text> : null}
            <Stack direction={{ initial: 'column', sm: 'row' }} gap={4}>
              {publicationDate ? (
                <Stack direction="row" gap={2} alignY="center">
                  <CalendarIcon size="xs" />
                  <Text size="label">{formatDate(publicationDate)}</Text>
                </Stack>
              ) : null}
              {regions?.length ? (
                <Stack direction="row" gap={1} alignY="center">
                  <PinDropIcon size="xs" />
                  <Text size="label">{regions.join(', ')}</Text>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </GridItem>
      </PageGrid>
      {image ? (
        <ImageWithTextComponent text={imageText || ''} image={<SitecoreImage field={image} />} />
      ) : null}
      <PageGrid>
        <GridItem columnStart={{ initial: '1', md: '3' }} columnEnd={{ initial: '-1', md: '-3' }}>
          <Stack alignX="start">
            <RichText>{body}</RichText>
            {button.text && button.href ? (
              <ButtonLink {...button} asChild>
                <NextLink href={button.href}>{button.text}</NextLink>
              </ButtonLink>
            ) : null}
          </Stack>
        </GridItem>
      </PageGrid>
      {highlight.cardImage ? (
        <ColoredSection color="inspiration">
          <Highlight
            highlights={[
              {
                image: <SitecoreImage field={highlight.cardImage} />,
                button:
                  highlightButton.href && highlightButton.text ? (
                    <NextLink href={highlightButton.href}>{highlightButton.text}</NextLink>
                  ) : undefined,
                text: highlight.cardText,
                title: highlight.title,
              },
            ]}
          />
        </ColoredSection>
      ) : null}
    </Stack>
  );
};

export default NewsArticleDetail;
