/**
 * This function returns a pattern based on the length input.
 * 
 * @param value The value of the input 1234
 * @param prefix string like 00
 * @returns a string '00 ####'
 */

export const basicInputPattern = (value: string, prefix: string | undefined): string => {
  const amountOfCharachters = value.length + 1;
  const patternLength = '#'.repeat(amountOfCharachters);
  const pattern = `${prefix} ${patternLength}`;

 return pattern
}