import { PreHide, ScrollLinkWrapper, SitecoreHighlight } from '@ads-core/components';
import { useAdsContext } from '@ads-core/providers';
import type { ImageField, LinkField, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreImage, SitecoreText, renderJssLink } from '@alliander-fe/jss-utils';
import { useAdobeTarget } from 'src/hooks/useAdobeTargetView';

export type HighlightJssResponse = ComponentProps & {
  rendering: {
    uid: string;
  };
  fields: {
    button1: LinkField;
    button2: LinkField;
    image1: ImageField;
    image2: ImageField;
    cardTitle1: TextField;
    cardTitle2: TextField;
    cardText1: TextField;
    cardText2: TextField;
    cardPosition1: { value: 'left' | 'right' };
    cardPosition2: { value: 'left' | 'right' };
    tag: TextField;
    title: TextField;
    newsOverviewButton: LinkField;
  };
  params?: {
    anchor?: string;
  };
};

const Highlight = (props: HighlightJssResponse) => {
  const adsContext = useAdsContext();
  const abTestVariant = props.params?.abTestVariant;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const { error, offer } = useAdobeTarget({
    mbox: 'highlight-mbox',
    selector: ref,
  });

  const renderAdobeTest = Boolean(
    !error && offer && offer[0]?.content[0]?.variant === abTestVariant
  );

  const {
    button1,
    button2,
    image1,
    image2,
    cardTitle1,
    cardTitle2,
    cardText1,
    cardText2,
    cardPosition1,
    cardPosition2,
    newsOverviewButton,
  } = props.fields;

  const highlights = [
    { button: button1, image: image1, title: cardTitle1, text: cardText1, position: cardPosition1 },
    { button: button2, image: image2, title: cardTitle2, text: cardText2, position: cardPosition2 },
  ];

  const mappedHighlights = highlights
    .filter((highlight) => {
      const isEditor = !!highlight.image.editable;
      const hasContent = !!highlight.text.value && !!highlight.title.value;

      // In Experience Editor, the image field will have a fallback image, so it's always filled
      if (isEditor) {
        // Do not show the highlight if the image is empty (which we check by checking for the className) and the title and text are empty
        return hasContent && highlight.image.value?.class !== 'scEmptyImage';
      }

      // We do not get a fallback image in the normal mode, just checking for a src is enough
      return !!highlight.image.value?.src && hasContent;
    })
    .map((highlight) => {
      return {
        button: renderJssLink(highlight.button),
        image: <SitecoreImage field={highlight.image} editable />,
        title: <SitecoreText field={highlight.title} editable tag="h3" />,
        text: <SitecoreText field={highlight.text} editable />,
        position: highlight.position.value,
      };
    });

  if (abTestVariant) {
    return (
      <PreHide isVisible={renderAdobeTest} ref={ref}>
        <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
          <SitecoreHighlight
            title={<SitecoreText field={props.fields.title} tag="h2" />}
            highlights={mappedHighlights}
            newsOverviewButton={renderJssLink(newsOverviewButton)}
            onColoredSection={adsContext.ids.includes(props.rendering.uid)}
          />
        </ScrollLinkWrapper>
      </PreHide>
    );
  }

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <SitecoreHighlight
        title={<SitecoreText field={props.fields.title} tag="h2" />}
        highlights={mappedHighlights}
        newsOverviewButton={renderJssLink(newsOverviewButton)}
        onColoredSection={adsContext.ids.includes(props.rendering.uid)}
      />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(Highlight);
