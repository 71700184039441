import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fglobal%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VW227jNhB971cMYCzQBRyBHN4k7lPWl8eif1BIouQ4cWxHcrJOiv57QW3cQkNOgcaIYJ%2B5HY7mkCz%2BkONeHaUS8OcvAHd3t9%2FSwwK%2FY4nu2wxHD4vt9DfHlYdFiXLj1nNce1gI4ap7NceNh4XeStSrOW6ZPI6pWzJ4xeA1k7%2BZeBrj7BxvGf6Byd8xeM%2FgO6bPDwyfPcP%2FkcnzxOQ5MOt6ZngeGfzE4GcGf2HwgeE%2FMv4Xxv%2BV8X9j8B9MnquHRW%2FiZ46%2FR1y2WvRz%2FMPDQtZNW7VzXAqmsIwK295v9VYQAzKvTKp%2FuCoao3mT4QhYjoBjeiJLZvhkVNtmtV5viJpllJtdycqWxBD1ZlbSmIoYWmZjkIGZWBklt642qy019FOELcsNMex%2BdnjlaEs41ckoO7f%2BrhVlFXW3MVuxpguMwutC77pADFF5neltTw1Ren3f646MnIza60MXklQnriVnbh0vXMQwDUPlJO1VFOBauErQXl08XIb6OJ7roTteiPGVG6G3%2F4r6wY3qlRu8d25aPpiFomBag5weEZniqBi6qLnD0zBdRssZOC0id%2FBhxdGNWsTOBYXEMJ19WJZBEkM7zbBtBY2IWgwKeyS7IHaMTjBq8V4JIWh3dx7E%2BQpSxGcZH8OuqX9Vbgkol6CWIAqBX5eTl5aFc86er6BUoRFl3l1q5Zz8jEEslLIq1nBFaSsmRFaItxCJhUFpJ1qFkA6ZmE93W1gjIhVTKBSizHuLUjhX2hurwlmHdvqGUpXMSoSxiJX8Spr24GF%2FHLsLiOkTg%2FNTss84Zqfm0U%2FG8XTYBybX08wlm%2BXgIfYq1ONDFxg5Pc99siI9etDnKwFP7BmH%2F942EtMLJ4eB2yFGTnIXZrPBV64Gd%2BtA7tqBV2a7xXdOWR%2BMslTc6ZoQqqYjhrjT1W1oeiLr6XLfNwFrcomZbve9aroklZ7US0AzvWEC2swrjbf6MgFLDyLCt39irqL5pzhvD%2BJRezDiCwGbHNM2RyrkSHUeZLqo3ufq7zxgWuvBA6bF9h4wrfboQaXVnjyotNrBg06rPXvQad6jB5NmOHmwKbOzB5dyePHTJk3QwUOV5h1jz1LnS2xaCr%2FGrqXwm4ffh9N1%2F1zDb6e3egn3w74%2BLGGsj%2BPd2A17cgTFG%2F3%2FC7h6sIl43mNXKfiRa6oWHmRBjk4tc29FY85V5V621tGVbE3a5KZF25yry42bLnOulYd4khK0zrk2HmRKoI2u5NaqQ04busu5RhmlXHefrn%2F9DXoeySOlEAAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fcomponents%2FAnimatedArrow%2FAnimatedArrow.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA72VXY%2BiMBSG7%2FkV52YTTcTw4TiKycZ%2FMqn0IB1Ly7bVcbPxv29A5UuQgTV7J3L6nIeX07I94O9IkQQ1fLifGK9T6cMfCyBSMsl%2FAHCMTAD2m%2FNjYwFcLAAja7eKOxdr%2B8hbtPO6ce5z3ttQnv2ct2znuWOfd93kGZl2xJffeU4jrbROWE92u2G0nuTCVlprcM0nnd8ZTl6QSs0MkyIAstOSHw1urPoio4jQkVRJcP3JicFJ5jfLLaebKvQ9h4aSSxXAiaiJbX%2B4mvnC9d1pRqsPBhEsIdf25ZD5jpNoQKLRZsKWR1PrsKp2YCJGxUwJ7uYue7heIKSZzEMpjJKcI50GsTyhgp9Q1LgzsKC89CrVcxIadsJadW66I%2BFhr%2BRRULs9FpXnspOKogrA%2BZ5UGXi%2FUuPltGTjt2VTi3SQ02qA06rLafHMyW1K0X%2Beu13PfODIuQvHzl35bAPipF1xrl%2F7inEG35fCLiky6B1H1%2F2UbxRbEcqOugi%2BPMYUcpK1z%2F7NnCMuvwKIGaUoNq37MT%2FVUqJQmOK8u8GaxeDMfZ0VRRzPto4VE4fmnt33jWLvieDUTgQ3PYOWnNEWWKVt%2FJ%2FaevW2rLutN4bHc94XoyYOwPfSc8aIke1jc7%2BuVCfV6oVTr75eX6xtgpQR0KFCFEAEhUnChH1b9b5cpedpzimw4vbtbGg0Ra5f2mKVrK%2B66zSFbh%2F1p1Ku4y0erNJRVr9eaLXIahtWapSVfqHV2nu0MqOsjgOs%2FgLWwbLzwAsAAA%3D%3D%22%7D"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var AnimatedArrow = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{size:{medium:{initial:'_1jeh9pol',sm:'_1jeh9pon',md:'_1jeh9pop',lg:'_1jeh9por',xl:'_1jeh9pot'},large:{initial:'_1jeh9pom',sm:'_1jeh9poo',md:'_1jeh9poq',lg:'_1jeh9pos',xl:'_1jeh9pou'}}},variantClassNames:{color:{onLight:{initial:'_1jeh9pog'},onDark:{initial:'_1jeh9poh'},ghostOnDark:{initial:'_1jeh9poi'}},direction:{leftToRight:{initial:'_1jeh9poj'},topToBottom:{initial:'_1jeh9pok'}}},compoundVariants:[],defaultVariants:{direction:'leftToRight',size:'medium',color:'onLight'},baseClassName:'_1jeh9pof'});
export var arrowWrap = '_1jeh9po2';
export var flyBase = '_1jeh9po0';
export var flyIn = '_1jeh9po7';
export var flyInAnimation = '_1jeh9po5';
export var flyInAnimationHover = '_1jeh9po3';
export var flyInTopToBottom = '_1jeh9pod';
export var flyInTopToBottomAnimation = '_1jeh9pob';
export var flyInTopToBottomAnimationHover = '_1jeh9po9';
export var flyOut = '_1jeh9po8';
export var flyOutAnimation = '_1jeh9po6';
export var flyOutAnimationHover = '_1jeh9po4';
export var flyOutTopToBottom = '_1jeh9poe';
export var flyOutTopToBottomAnimation = '_1jeh9poc';
export var flyOutTopToBottomAnimationHover = '_1jeh9poa';
export var innerWrap = '_1jeh9po1';