import {
  ImageField,
  RichTextField,
  TextField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'lib/component-props';
import { OutagesSMSServiceView } from 'src/components/OutagesSMSService';
import {
  FilterValuePropertyFromFields,
  mapWithoutValueProperty,
} from 'src/utils/mapWithoutValueProperty';

export type OutagesSMSServiceProps = {
  fields: {
    checkboxLabel?: TextField;
    errorMessageText?: TextField;
    errorMessageTitle?: TextField;
    image?: ImageField;
    nrExistsOnAddressText: TextField;
    nrExistsOnAddressTitle: TextField;
    nrUsedOnTooManyAddressesText: TextField;
    nrUsedOnTooManyAddressesTitle: TextField;
    outageNoLongerExistsText?: RichTextField;
    outageNoLongerExistsTitle?: TextField;
    submitButtonLabel?: TextField;
    successMessageText?: TextField;
    successMessageTitle?: TextField;
    text?: RichTextField;
    title?: TextField;
  };
} & ComponentProps;

export type FilteredOutagesSMSServiceProps = FilterValuePropertyFromFields<
  OutagesSMSServiceProps['fields']
>;

const OutagesSMSService = (props: OutagesSMSServiceProps) => {
  const fields = mapWithoutValueProperty(props.fields);
  return fields ? <OutagesSMSServiceView {...fields} /> : null;
};

export default withDatasourceCheck()<OutagesSMSServiceProps>(OutagesSMSService);
