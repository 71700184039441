import { InputNumber } from '@ads-core/components';
import { NumberInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { PatternFormat } from 'react-number-format';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getOptions } from '@alliander-fe/validation';
import { encodeNameToReactHookFormFormat, extractString, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { PropsWithConditions } from '../types';
import { basicInputPattern } from '../utils/basicInputPattern';

type NumberInputViewModelWithPlaceholderText = {
  placeholderText?: string;
  prefix?: string;
} & NumberInputViewModel;

type Props = PropsWithConditions<ValueFieldProps<NumberInputViewModelWithPlaceholderText>>;

export const NumberFieldMapper = ({ field, tracker }: Props) => {
  const { formState, getValues, setValue, register } = useFormContext();
  const prefix = `${field.model.prefix} `;

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const error = extractString(formState.errors[name]?.message);
  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  if (isHidden) return null;
  const options = getOptions(field);
  const methods = register(name, options);

  if (prefix) {
    const pattern = basicInputPattern(getValues(name) || '', prefix);

    return (
      <PatternFormat
        error={error}
        format={`${pattern}#`}
        customInput={InputNumber}
        tone="onLight"
        label={getLabel(field.model.title, !!options.required)}
        className={field.model.cssClass}
        step={field.model.step}
        min={field.model.min}
        max={field.model.max}
        {...methods}
        placeholder={field.model.placeholderText}
        onFocus={() => tracker.onFocusField(field, getValues(name))}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          // This will only allow users to enter numbers and keys like backspace, tab etc.
          if (e.key.length === 1 && !/^[0-9,.]*$/.test(e.key)) {
            e.preventDefault();
          }
        }}
        onBlur={(e) => {
          tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
          methods.onBlur(e);
        }}
        onValueChange={({ value }) => {
          setValue(name, value);
        }}
        allowEmptyFormatting
      />
    );
  }

  return (
    <InputNumber
      error={error}
      tone="onLight"
      label={getLabel(field.model.title, !!options.required)}
      className={field.model.cssClass}
      step={field.model.step}
      min={field.model.min}
      max={field.model.max}
      {...methods}
      placeholder={field.model.placeholderText}
      onFocus={() => tracker.onFocusField(field, getValues(name))}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        // This will only allow users to enter numbers and keys like backspace, tab etc.
        if (e.key.length === 1 && !/^[0-9,.]*$/.test(e.key)) {
          e.preventDefault();
        }
      }}
      onBlur={(e) => {
        tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
        methods.onBlur(e);
      }}
    />
  );
};
