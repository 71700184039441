import { ComponentRendering, HtmlElementRendering } from "@sitecore-jss/sitecore-jss-nextjs";

export function isComponentRendering(
  component?: ComponentRendering | HtmlElementRendering
): component is ComponentRendering {
  return (
    typeof component !== 'undefined' &&
    'componentName' in component &&
    component.componentName !== ''
  );
}