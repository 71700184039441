import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import { IntegrationApi } from '@alliander-fe/api';

import {
  Heading,
  Text,
  Stack,
  Grid,
  Box,
  GridItem,
  DictionaryDialog,
  RichText,
  SubpageHeroSitecore,
  PageGrid,
  ScrollLinkWrapper,
  PostalCodeCheckForm,
  CTABlock,
  ButtonLink,
  Alert,
} from '@ads-core/components';
import { ArrowRightIcon } from '@ads-core/icons';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { useTrackingContext, useTrackingFormTouch } from '@liander/context';
import { mq } from '@ads-core/breakpoints';
import { useMedia } from '@ads-core/hooks';
import { useAdsContext } from '@ads-core/providers';
import { scrollToElement } from '@ads-core/utils';
import {
  houseNumberPattern,
  postalCodePattern,
  requiredFieldPattern,
} from '@alliander-fe/validation';

import { FilteredCongestionCheckerParticulierProps } from 'components/CongestionCheckerParticulier';

import { baseQueryParams, congestionLevel, formatApiPostalCode, formatPostalCode } from 'src/utils';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useParams } from 'src/hooks/useParams';

import { PolygonMap } from '../PolygonMap';
import { CongestionFeedback } from '../CongestionFeedback';

import { CongestionMessage } from './_Message';
import { mappedResponse } from './utils/mappedResponse';
import { mappedErrorResponse } from './utils/mappedErrorResponse';

type Address = {
  postalCode: string;
  houseNumber: string;
  houseNumberAddition?: string;
};

const connectionOrigin = {
  closest: 'DichtstbijzijndeLSVerbinding',
  existing: 'BestaandeLSVerbinding',
} as const;

export const CongestionCheckerParticulier = (props: FilteredCongestionCheckerParticulierProps) => {
  const { trackWizardSimpleChange } = useTrackingContext();
  const router = useRouter();
  const [toggleMessage, setToggleMessage] = React.useState(false);
  const hasExplanation = Boolean(props?.explanation);
  const notificationRef = React.useRef<HTMLDivElement>(null);
  const adsContext = useAdsContext();
  const isDesktop = useMedia(mq.md);

  const { postalCode, houseNumber, houseNumberAddition, city } = useParams();

  const replaceText = useMemo(() => {
    return {
      '{postalCode}': formatPostalCode(postalCode) ?? '',
      '{houseNumber}': houseNumber ?? '',
      '{houseNumberAddition}': houseNumberAddition ?? '',
      '{places}': city ?? '',
    };
  }, [city, houseNumber, houseNumberAddition, postalCode]);

  const { formState, handleSubmit, register } = useForm({
    mode: 'onTouched',
    defaultValues: {
      postalCode: postalCode,
      houseNumber: houseNumber,
      houseNumberAddition: houseNumberAddition,
    },
  });

  const query = useQuery({
    enabled: Boolean(postalCode) && Boolean(houseNumber),
    queryKey: [postalCode, houseNumber, houseNumberAddition],
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: () =>
      IntegrationApi.congestionEndpointsGetSmallScaleCongestions({
        PostalCode: formatApiPostalCode(postalCode) ?? '',
        HouseNumber: Number(houseNumber),
        Addition: houseNumberAddition ?? null,
      }),
    select: ({ errorCode, level, origin }) => {
      const mappedLevel = congestionLevel(level);

      const errorResponse = errorCode
        ? mappedErrorResponse({
            code: errorCode,
            ...props,
          })
        : undefined;

      if (errorResponse) {
        return {
          ...errorResponse,
          response: errorCode,
        };
      }

      return {
        ...mappedResponse({
          level: mappedLevel,
          disclaimer: origin === connectionOrigin.closest,
          replaceText,
          ...props,
        }),
        response: mappedLevel,
      };
    },
  });

  const { fetchStatus, data, isError } = query;

  useEffect(() => {
    if (data?.response) {
      // We keep track of the output of the api, the action name is a bit weird but the guys form tracking wanted to name it this way.
      trackWizardSimpleChange({
        action: 'close wizard',
        actionDetail: 'presenteren output wizard schaarstechecker',
        wizardName: 'schaarstechecker',
        wizardStep: '2.1',
        wizardOutput: data.response || '',
      });
    }
  }, [data?.response, trackWizardSimpleChange]);

  const congestionData = useMemo(() => {
    return isError
      ? {
          ...mappedErrorResponse({
            code: '',
            ...props,
          }),
        }
      : data;
  }, [isError, props, data]);

  const onSubmit: SubmitHandler<Address> = (data) => {
    const { postalCode, houseNumber, houseNumberAddition } = data;

    const params = new URLSearchParams();
    params.set(baseQueryParams.postalCode, postalCode.toUpperCase());
    params.set(baseQueryParams.houseNumber, houseNumber);

    if (houseNumberAddition) {
      params.set(baseQueryParams.houseNumberAddition, houseNumberAddition);
    }

    const parsedParams = decodeURIComponent(params.toString());
    const pathname = router.asPath.split('?')[0];

    router.push(
      {
        pathname: pathname,
        search: parsedParams,
      },
      undefined,
      { scroll: false }
    );
  };

  useTrackingFormTouch({
    wizardName: 'schaarsteChecker',
    wizardStep: '1',
    fields: formState.touchedFields,
  });

  useEffect(() => {
    if (!isDesktop && (congestionData?.feedbackText || congestionData?.feedbackTitle)) {
      scrollToElement(notificationRef, adsContext.topHeaderHeight + 32);
    }

    if (congestionData) {
      setToggleMessage(true);
    }
  }, [adsContext.topHeaderHeight, congestionData, isDesktop]);

  return (
    <Stack gap={{ initial: 12, md: 30 }}>
      <ScrollLinkWrapper anchorId="schaarste-checker">
        <Stack gap={{ initial: 0, md: 16 }}>
          <SubpageHeroSitecore
            text={
              props?.title ? (
                <Heading
                  size="h1"
                  align={{
                    initial: 'center',
                    md: hasExplanation ? 'start' : 'center',
                  }}
                >
                  <DictionaryDialog>{props.title.toString()}</DictionaryDialog>
                </Heading>
              ) : undefined
            }
            explanation={
              props?.explanation ? (
                <Stack alignY="end">
                  <Text align={{ initial: 'center', md: 'start' }} as="div">
                    <RichText>{props?.explanation}</RichText>
                  </Text>
                </Stack>
              ) : undefined
            }
          />
          <PageGrid>
            <GridItem columnEnd="-1" columnStart="1">
              <CTABlock>
                <CTABlock.Column
                  columnStart="1"
                  columnEnd={{ initial: '-1', md: '7' }}
                  variant="main"
                  bg="containerPrimary"
                >
                  <Stack gap={4}>
                    {props?.inputFormTitle ? (
                      <Heading size="h3" as="h2" color="onDark">
                        {props?.inputFormTitle}
                      </Heading>
                    ) : null}
                    {props?.inputFormText ? (
                      <Text color="onDark">{props?.inputFormText}</Text>
                    ) : null}
                  </Stack>
                  <Box paddingTop={{ initial: 6, md: 10 }}>
                    <PostalCodeCheckForm color="none" handleOnSubmit={handleSubmit(onSubmit)}>
                      <Grid columns={12} gap={4} rowGap={4}>
                        <GridItem columnStart="1" columnEnd={{ initial: '-1', lg: '7' }} asChild>
                          <Stack isFullWidth>
                            <PostalCodeCheckForm.Input
                              label={props?.postcode?.toString() ?? 'Postcode'}
                              tone="onDark"
                              placeholder="1234AB"
                              error={formState?.errors?.postalCode?.message as string | undefined}
                              {...register('postalCode', {
                                required: requiredFieldPattern('Postcode'),
                                pattern: postalCodePattern,
                              })}
                            />
                          </Stack>
                        </GridItem>
                        <GridItem columnStart={{ initial: '1', lg: '7' }} columnEnd="-1">
                          <Stack isFullWidth asChild>
                            <PostalCodeCheckForm.Addendum
                              label={props?.huisnr?.toString() ?? 'Huisnr. en toevoeging'}
                              error={formState?.errors?.houseNumber?.message as string | undefined}
                              tone="onDark"
                              houseNumber={{
                                label: 'Huisnummer',
                                placeholder: '10',
                                error: formState?.errors?.houseNumber?.message as
                                  | string
                                  | undefined,
                                ...register('houseNumber', {
                                  required: requiredFieldPattern('Huisnummer'),
                                  pattern: houseNumberPattern,
                                }),
                              }}
                              addition={{
                                label: 'Toevoeging',
                                placeholder: 'A',
                                ...register('houseNumberAddition'),
                              }}
                            />
                          </Stack>
                        </GridItem>
                        <GridItem columnStart="1" columnEnd="-1">
                          <Box
                            width={{ initial: '100%', md: 'auto' }}
                            paddingTop={{ initial: 2, md: 8 }}
                          >
                            <PostalCodeCheckForm.SubmitButton
                              size="large"
                              tone="onDark"
                              variant="secondary"
                              isLoading={fetchStatus === 'fetching'}
                              onClick={() =>
                                trackWizardSimpleChange({
                                  action: 'submit',
                                  actionDetail: 'bevestigen details schaarstechecker',
                                  wizardName: 'schaarstechecker',
                                  wizardStep: '2',
                                })
                              }
                            >
                              {props?.submitButtonLabel ?? 'Controleer uw postcode'}
                            </PostalCodeCheckForm.SubmitButton>
                          </Box>
                        </GridItem>
                      </Grid>
                    </PostalCodeCheckForm>
                  </Box>
                </CTABlock.Column>
                <CTABlock.Column columnStart={{ initial: '1', md: '7' }} columnEnd="-1">
                  <CTABlock.ImageContainer
                    overlay={
                      congestionData && toggleMessage ? (
                        <Box ref={notificationRef} width="100%">
                          <CTABlock.Notification
                            handleOnClose={() => setToggleMessage((toggle) => !toggle)}
                            arrowColor="containerPrimary"
                          >
                            <CongestionMessage
                              title={congestionData.feedbackTitle}
                              description={congestionData.feedbackText}
                              buttonPrimary={congestionData.feedbackPrimaryButton}
                              buttonSecondary={congestionData.feedbackSecondaryButton}
                            />
                          </CTABlock.Notification>
                        </Box>
                      ) : null
                    }
                  >
                    {postalCode || (!isDesktop && postalCode && !toggleMessage) ? (
                      <Box width="100%" height={{ initial: '358px', md: '100%' }} overflow="hidden">
                        <PolygonMap postalCode={postalCode} />
                      </Box>
                    ) : (
                      <CTAFallBack />
                    )}
                  </CTABlock.ImageContainer>
                </CTABlock.Column>
              </CTABlock>
            </GridItem>
          </PageGrid>
        </Stack>
      </ScrollLinkWrapper>
      {congestionData?.additionalInfoBlockTitle ||
      congestionData?.additionalInfoBlockText ||
      congestionData?.disclaimer ? (
        <PageGrid>
          <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
            <Stack gap={10}>
              {congestionData?.disclaimer ? (
                <Alert variant="info">
                  <RichText>{congestionData?.disclaimer}</RichText>
                </Alert>
              ) : null}

              <ScrollLinkWrapper anchorId="additional">
                <CongestionFeedback
                  title={congestionData.additionalInfoBlockTitle}
                  description={congestionData.additionalInfoBlockText}
                  buttonPrimary={congestionData.additionalInfoBlockSecondaryButton}
                  buttonSecondary={congestionData.feedbackSecondaryButton}
                />
              </ScrollLinkWrapper>
            </Stack>
          </GridItem>
        </PageGrid>
      ) : null}
      {congestionData?.conclusionUpperText || congestionData?.conclusionLowerText ? (
        <PageGrid>
          <GridItem columnStart={{ initial: '1', lg: '2' }} columnEnd={{ initial: '-1', lg: '-2' }}>
            <ScrollLinkWrapper anchorId="conclusion">
              <CTABlock>
                <CTABlock.Column bg="containerPrimary" variant="main">
                  <Stack gap={6}>
                    <ConclusionBlock
                      text={congestionData.conclusionUpperText}
                      primaryButton={congestionData.conclusionFirstPrimaryButton}
                      secondaryButton={congestionData.conclusionSecondPrimaryButton}
                    />

                    <ConclusionBlock
                      text={congestionData.conclusionLowerText}
                      primaryButton={congestionData.conclusionSecondPrimaryButton}
                      secondaryButton={congestionData.conclusionSecondSecondaryButton}
                    />
                  </Stack>
                </CTABlock.Column>
              </CTABlock>
            </ScrollLinkWrapper>
          </GridItem>
        </PageGrid>
      ) : null}
    </Stack>
  );
};

export const ConclusionBlock = ({
  text,
  primaryButton,
  secondaryButton,
}: {
  text?: string;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
}) => {
  const { trackPageInteraction } = useTrackingContext();
  if (!text) return null;

  return (
    <>
      {text ? <RichText tone="onDark">{text}</RichText> : null}
      {primaryButton?.text || secondaryButton?.text ? (
        <Stack direction="row" wrap gap={4}>
          {primaryButton?.text ? (
            <ButtonLink
              variant="secondary"
              {...primaryButton}
              afterIcon={<ArrowRightIcon size="xs" />}
              asChild
              onClick={() =>
                trackPageInteraction({
                  actionDetail: primaryButton.text ?? '',
                  actionType: 'button_click',
                })
              }
            >
              {primaryButton.text}
            </ButtonLink>
          ) : null}
          {secondaryButton?.text ? (
            <ButtonLink
              variant="ghostOnDark"
              {...secondaryButton}
              afterIcon={<ArrowRightIcon size="xs" />}
              asChild
              onClick={() =>
                trackPageInteraction({
                  actionDetail: secondaryButton.text ?? '',
                  actionType: 'button_click',
                })
              }
            >
              {secondaryButton.text}
            </ButtonLink>
          ) : null}
        </Stack>
      ) : null}
    </>
  );
};

const CTAFallBack = () => {
  const isDesktop = useMedia(mq.md);

  if (!isDesktop) return null;

  return (
    <>
      <CTABlock.NotificationArrow arrowColor="containerPrimary" />
      <Box
        width="100%"
        height={{ initial: '358px', md: '100%' }}
        overflow="hidden"
        bg="containerLight"
        padding={5}
      >
        <Box position="relative" width="100%" height="100%">
          <CTABlock.Image objectFit="contain">
            <Image src="/assets/Liander_Regio_Blauw.png" alt="" fill />
          </CTABlock.Image>
        </Box>
      </Box>
    </>
  );
};
