import { StringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import { InputText } from '@ads-core/components';
import { PatternFormat } from 'react-number-format';
import { isIBAN, getOptions } from '@alliander-fe/validation';
import { extractString, encodeNameToReactHookFormFormat, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { PropsWithConditions, SingleLineInputViewWithParameters } from '../types';
import { getValidationModels } from '../utils/getValidationModels';
import { validateInput } from '../utils/validateInput';
import { basicInputPattern } from '../utils/basicInputPattern';

type Props = PropsWithConditions<
  ValueFieldProps<SingleLineInputViewWithParameters<StringInputViewModel>>
>;

export const InputMapper = ({ field, tracker }: Props) => {
  const { register, formState, getValues, setValue } = useFormContext();
  const prefix = field.model.prefix;

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const error = extractString(formState.errors[name]?.message);

  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  if (isHidden) return null;

  const {
    exact18NumbersValidator,
    exact8NumbersValidator,
    isPostalCodeValidator,
    isIBANValidator,
    onlyNumbersValidator,
    isNameValidator,
    stringLengthValidator,
    conditionallyRequiredValidator,
  } = getValidationModels(field.model.validationDataModels);

  const inputModeNumeric = exact18NumbersValidator || exact8NumbersValidator;

  const options = getOptions(field);
  const methods = register(name, {
    ...options,
    validate: {
      IBAN: (v) => {
        return isIBANValidator ? isIBAN(v, isIBANValidator.message) : true;
      },
      validationModel: (v) => {
        const validation = validateInput(v, [
          exact18NumbersValidator,
          exact8NumbersValidator,
          isPostalCodeValidator,
          isNameValidator,
          onlyNumbersValidator,
          stringLengthValidator,
          conditionallyRequiredValidator,
        ]);

        if (validation) {
          return validation;
        }
      },
    },
  });

  if (prefix) {
    const pattern = basicInputPattern(getValues(name) || '', prefix);

    return (
      <PatternFormat
        format={pattern}
        customInput={InputText}
        label={getLabel(field.model.title, !!options.required)}
        {...methods}
        placeholder={field.model.placeholderText}
        onFocus={() => tracker.onFocusField(field, getValues(name))}
        onBlur={(e) => {
          tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
          methods.onBlur(e);
        }}
        inputMode={inputModeNumeric ? 'numeric' : undefined}
        error={error}
        onValueChange={(v) => {
          let value = v.value;

          // Because a IBAN number is always in uppercase we change the input.
          if (isIBANValidator) {
            value = value.toUpperCase();
          }

          // Because a name always with a uppercase we change the input.
          if (isNameValidator) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
          }

          setValue(name, value);
        }}
        allowEmptyFormatting
      />
    );
  }

  return (
    <InputText
      label={getLabel(field.model.title, !!options.required)}
      {...methods}
      placeholder={field.model.placeholderText}
      onFocus={() => tracker.onFocusField(field, getValues(name))}
      onBlur={(e) => {
        tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
        methods.onBlur(e);
      }}
      onChange={(e) => {
        let value = e.target.value;

        // Because a IBAN number is always in uppercase we change the input.
        if (isIBANValidator) {
          value = value.toUpperCase();
        }

        // When the name validator is active we change the first letter to a uppercase.
        if (isNameValidator) {
          value = value.charAt(0).toUpperCase() + value.slice(1);
        }

        setValue(name, value);
      }}
      inputMode={inputModeNumeric ? 'numeric' : undefined}
      error={error}
    />
  );
};
