import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fglobal%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VW227jNhB971cMYCzQBRyBHN4k7lPWl8eif1BIouQ4cWxHcrJOiv57QW3cQkNOgcaIYJ%2B5HY7mkCz%2BkONeHaUS8OcvAHd3t9%2FSwwK%2FY4nu2wxHD4vt9DfHlYdFiXLj1nNce1gI4ap7NceNh4XeStSrOW6ZPI6pWzJ4xeA1k7%2BZeBrj7BxvGf6Byd8xeM%2FgO6bPDwyfPcP%2FkcnzxOQ5MOt6ZngeGfzE4GcGf2HwgeE%2FMv4Xxv%2BV8X9j8B9MnquHRW%2FiZ46%2FR1y2WvRz%2FMPDQtZNW7VzXAqmsIwK295v9VYQAzKvTKp%2FuCoao3mT4QhYjoBjeiJLZvhkVNtmtV5viJpllJtdycqWxBD1ZlbSmIoYWmZjkIGZWBklt642qy019FOELcsNMex%2BdnjlaEs41ckoO7f%2BrhVlFXW3MVuxpguMwutC77pADFF5neltTw1Ren3f646MnIza60MXklQnriVnbh0vXMQwDUPlJO1VFOBauErQXl08XIb6OJ7roTteiPGVG6G3%2F4r6wY3qlRu8d25aPpiFomBag5weEZniqBi6qLnD0zBdRssZOC0id%2FBhxdGNWsTOBYXEMJ19WJZBEkM7zbBtBY2IWgwKeyS7IHaMTjBq8V4JIWh3dx7E%2BQpSxGcZH8OuqX9Vbgkol6CWIAqBX5eTl5aFc86er6BUoRFl3l1q5Zz8jEEslLIq1nBFaSsmRFaItxCJhUFpJ1qFkA6ZmE93W1gjIhVTKBSizHuLUjhX2hurwlmHdvqGUpXMSoSxiJX8Spr24GF%2FHLsLiOkTg%2FNTss84Zqfm0U%2FG8XTYBybX08wlm%2BXgIfYq1ONDFxg5Pc99siI9etDnKwFP7BmH%2F942EtMLJ4eB2yFGTnIXZrPBV64Gd%2BtA7tqBV2a7xXdOWR%2BMslTc6ZoQqqYjhrjT1W1oeiLr6XLfNwFrcomZbve9aroklZ7US0AzvWEC2swrjbf6MgFLDyLCt39irqL5pzhvD%2BJRezDiCwGbHNM2RyrkSHUeZLqo3ufq7zxgWuvBA6bF9h4wrfboQaXVnjyotNrBg06rPXvQad6jB5NmOHmwKbOzB5dyePHTJk3QwUOV5h1jz1LnS2xaCr%2FGrqXwm4ffh9N1%2F1zDb6e3egn3w74%2BLGGsj%2BPd2A17cgTFG%2F3%2FC7h6sIl43mNXKfiRa6oWHmRBjk4tc29FY85V5V621tGVbE3a5KZF25yry42bLnOulYd4khK0zrk2HmRKoI2u5NaqQ04busu5RhmlXHefrn%2F9DXoeySOlEAAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fcomponents%2FTopTasks%2FTopTasks.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA52U3W6jMBCF7%2FMUo0qVkgtXgEmadW%2FyJpWxh2CV2KxtUrqrvPuKnyCThF22dzAz55vxwcPhA79yy0%2Fo4OdOHettBL9XALk1p%2B4BoMTcMyBxFD2%2FrQAuKwBvJrmoj19Wh1tY%2FBgWzYCCJg9gSVd7IwnHmuvzgEUfD7bkkC89YdtlMy4%2BjtbUWhJhSmMZnLldE%2FIeO0V1TGOxeQs0TPuCiEKVcp1slgGyOQBdCOBzgHQhYD8FFOaM9huH3y3TFBPNNww7zgGWGpbPAZYaJqeA%2FzBscvjXTnNSmnwq6QsGdJdWTVCw7y9xiQ3JuFOOwba9vmP%2Bx12e0hdKKQ2L%2BF1Rsg3z2T%2Fyot9KbDyRKIzlXhnNQBuN7cZUxqk%2BYrHkXp1xou2787JkUGuHvpWI2rrWm8oo7dFO6xnLMDcWO50w2qP2DJ6epr145kxZ%2B24Cb6phe4NFHhy97nuB6lgE%2B3%2F%2Fobzl2lXcou5m%2FEWUlth0tHE8OVrFIA7ieRCnbfxwQqk4OGERNXAtYR185jhNo6rp79pASIdf0chuX1rJdfCklYx%2Fu7%2FiX3f7GzoO9BB4deLaMxngfwDM76%2BOMAYAAA%3D%3D%22%7D"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var TopTaskLink = 'q6igu5c';
export var TopTasks = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{color:{purple:{initial:'q6igu55'},blue:{initial:'q6igu56'}}},compoundVariants:[],defaultVariants:{color:'purple'},baseClassName:'q6igu54'});
export var arrowHover = 'q6igu50';
export var arrowHoverOut = 'q6igu51';
export var arrowWhiteHover = 'q6igu52';
export var arrowWhiteHoverOut = 'q6igu53';
export var task = 'q6igu5b';
export var taskBlock = 'q6igu5f';
export var tasksWrapper = 'q6igu5d';
export var variableBlockMobile = 'q6igu5e';
export var variableBlockTask = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{items:{'2':{initial:'q6igu58'},'3':{initial:'q6igu59'},'4':{initial:'q6igu5a'}}},compoundVariants:[],defaultVariants:{},baseClassName:'q6igu57'});