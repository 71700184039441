import { useRouter } from 'next/router';
import React from 'react';

export type ABTestElement = {
  id: string;
  version: string;
  reference: string;
  params?: {
    [key: string]: string | number;
  };
};

/**
 * Checks if Adobe Target is available on the website
 * @returns Boolean
 */
const useAdobeTargetAvailability = () => {
  const [isAdobeTargetAvailable, setIsAdobeTargetAvailable] = React.useState(false);

  React.useEffect(() => {
    // Check if already available
    if (window?.adobe?.target) {
      setIsAdobeTargetAvailable(true);
      return;
    }

    // If not available, set up an observer
    const observer = new MutationObserver(() => {
      if (window?.adobe?.target) {
        setIsAdobeTargetAvailable(true);
        observer.disconnect();
      }
    });

    // Start observing the document for changes
    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return isAdobeTargetAvailable;
};

/**
 * Enables Adobe Target View
 */
export const useAdobeTargetView = () => {
  const router = useRouter();

  React.useEffect(() => {
    const pathname = window.location.pathname ?? 'not available';
    const sanitizedPageName = pathname.startsWith('/') ? pathname.substring(1) : pathname;

    // When the sanitizedPageName is empty we know it's the homepage.
    const triggeredView = sanitizedPageName || 'home';

    // Validate if the Target Libraries are available on your website
    if (window?.adobe?.target?.triggerView) {
      window.adobe.target.triggerView(triggeredView);
    }
  }, [router]);
};

type AdobeTargetProps<T> = {
  mbox: string;
  selector: React.RefObject<T>;
};

export const useAdobeTarget = <T extends HTMLElement>({ mbox, selector }: AdobeTargetProps<T>) => {
  const [offer, setOffer] = React.useState<AdobeTargetGetOfferPromise[] | null>(null);
  const [error, setError] = React.useState<boolean>(false);
  const isAdobeTargetAvailable = useAdobeTargetAvailability();

  React.useEffect(() => {
    const adobe = window?.adobe?.target;
    if (!adobe || !selector.current) return;

    adobe.getOffer({
      mbox,
      timeOut: 3000,
      success: (response) => {
        setOffer(response);
        setError(false);
        adobe.applyOffer({
          mbox,
          selector: selector.current,
          offer: response,
        });
      },
      error: ({ error, status }) => {
        console.error(`Something went wrong ${error} ${status}`);
        setError(true);
      },
    });
  }, [mbox, selector, isAdobeTargetAvailable]);

  return {
    offer,
    error,
    isAdobeTargetAvailable,
  };
};

type AdobeTargetMBoxes = {
  mboxes: {
    index: number;
    name: string;
    parameters?: {
      [key: string]: string | number;
    };
    profileParameters?: {
      [key: string]: string | number;
    };
  }[];
};

type AdobeTargetPrefetch = {
  prefetch: AdobeTargetMBoxes;
};

type AdobeTargetGetOffers = {
  customerId?: string;
  decisioningMethod?: 'server-side' | 'on-device' | 'hybrid';
  request: AdobeTargetPrefetch;
  timeOut?: number;
};

type AdobeTargetOfferContent = {
  [key: string]: string | number;
};

type AdobeTargetGetOfferPromise = {
  action: string;
  selector: string;
  cssSelector: string;
  content: AdobeTargetOfferContent[];
};

type AdobeTargetGetOfferError = {
  status: string;
  error: string;
};

type AdobeTargetGetOffer = {
  mbox: string;
  timeOut: number;
  params?: {
    [key: string]: string | number;
  };
  success: (response: AdobeTargetGetOfferPromise[]) => void;
  error?: (response: AdobeTargetGetOfferError) => void;
};

type AdobeTargetApplyOffer = {
  mbox: string;
  selector: any;
  offer: unknown;
};

type AdobeTargetApplyOffers = {
  selector: string;
  response: AdobeTargetPrefetch;
};

declare global {
  interface Window {
    adobe?: {
      target?: {
        triggerView?: (value: string, object?: { page: boolean } | undefined) => void;
        getOffers: (props: AdobeTargetGetOffers) => Promise<AdobeTargetPrefetch>;
        applyOffers: (props: AdobeTargetApplyOffers) => void;
        getOffer: (props: AdobeTargetGetOffer) => void;
        applyOffer: (props: AdobeTargetApplyOffer) => void;
      };
    };
  }
}

